import { useSettingsContext } from 'src/components/settings';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import { SettingsListView } from 'src/modules/settings/views/settings-list-view';

export default function SettingsListPage() {
  const settings = useSettingsContext();

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <SettingsListView />
      </Container>
    </>
  );
}

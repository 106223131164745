import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'DD MMM YYYY';

  return date ? dayjs(date).format(fm) : '';
}

// Convert seconds to minutes
export function fSecondsToTime(seconds: number, newFormat?: string) {
  const fm = newFormat || 'HH:mm:ss';

  return `${dayjs.duration(seconds * 1000).format(fm)}`;
}

export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'HH:mm:ss';

  return date ? dayjs(date).format(fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'DD MMM YYYY';

  return date ? dayjs(date).format(fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? dayjs(date).millisecond() : '';
}

export function fToNow(date: InputValue) {
  return date ? dayjs(date).fromNow() : '';
}

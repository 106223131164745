import { useEffect } from 'react';
import { paths } from 'src/routes/paths';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter, useSearchParams } from 'src/routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const { replace } = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo') || paths.dashboard.root;

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    // getAccessTokenSilently()
    //   .catch((error) => {
    //     console.error(error);
    //   })
    //   .then((res) => {
    //     console.log('res', res);
    //   });

    replace(returnTo);
  }, [isAuthenticated, returnTo, getAccessTokenSilently, replace]);

  return <>{children}</>;
}

import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Button, Table, TableBody, TableContainer } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { TableHeadCustom } from 'src/components/table';
import { useSnackbar, SnackbarKey } from 'src/components/snackbar';
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from 'src/services/settings';
import SettingsTableRow from '../settings-table-row';

const TABLE_HEAD = [
  { id: 'key', label: 'Key' },
  { id: 'value', label: 'Value' },
];

export function SettingsListView() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data, isLoading } = useGetSettingsQuery();
  const { mutateAsync, isPending } = useUpdateSettingsMutation();
  const form = useForm();

  useEffect(() => {
    form.reset(
      (data?.getSettings ?? []).reduce(
        (acc, item) => {
          acc[item.key] = item.value;
          return acc;
        },
        {} as Record<string, string>
      )
    );
  }, [data, form]);

  let snackbar: SnackbarKey | undefined;

  const handleSaveAll = async () => {
    const values = form.getValues();
    const items = Object.keys(values).map((key) => ({
      key,
      value: values[key],
    }));

    snackbar = enqueueSnackbar('Update settings...', {
      variant: 'info',
      autoHideDuration: null,
    });

    await mutateAsync({ data: { items } })
      .then(() => {
        closeSnackbar(snackbar);

        snackbar = enqueueSnackbar('Saved Successfully', {
          variant: 'success',
        });
      })
      .catch(() => {
        closeSnackbar(snackbar);

        snackbar = enqueueSnackbar('Error saving settings', {
          variant: 'error',
        });
      });
  };

  const items = data?.getSettings ?? [];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={5}
      >
        <Typography variant="h4">Settings</Typography>
      </Stack>

      <FormProvider {...form}>
        <Card sx={{ overflow: 'unset' }}>
          <TableContainer sx={{ position: 'relative' }}>
            <Table>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              <TableBody>
                {items.map((item) => (
                  <SettingsTableRow key={item.key} row={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Save button */}
          <Stack direction="row" justifyContent="flex-end" padding={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading || isPending}
              onClick={handleSaveAll}
            >
              Save all
            </Button>
          </Stack>
        </Card>
      </FormProvider>
    </>
  );
}

import { TableCell, TableRow, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SettingsModel } from 'src/gql/graphql';

type Props = {
  row: SettingsModel;
};

export default function SettingsTableRow(props: Props) {
  const form = useFormContext();

  return (
    <TableRow>
      <TableCell>{props?.row.key}</TableCell>
      <TableCell>
        <Controller
          control={form.control}
          name={props?.row.key}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Value" variant="outlined" fullWidth />
          )}
        />
      </TableCell>
    </TableRow>
  );
}

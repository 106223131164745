import 'simplebar-react/dist/simplebar.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-quill/dist/quill.snow.css';

import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppRouter } from 'src/routes/sections';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import ThemeProvider from 'src/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from './components/snackbar';
import { queryClient } from './services/_request';
import { AuthProvider } from './auth/context/auth0';

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <SnackbarProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MotionLazy>
                  <SettingsDrawer />
                  <AppRouter />
                </MotionLazy>
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

import type { GetTokenSilentlyOptions } from '@auth0/auth0-react';

// Global state for access token
let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

// Access token for base query
export const accessToken = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => {
    getAccessTokenSilently = func;
  },
};

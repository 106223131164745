import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { replace } = useRouter();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    const searchParams = new URLSearchParams({
      returnTo: window.location.pathname + window.location.search,
    }).toString();

    const href = `${paths.auth}?${searchParams}`;

    replace(href);
  }, [replace, isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
}

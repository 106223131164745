import ProgressBar from 'src/components/progress-bar';
import Stack from '@mui/material/Stack';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  useScrollToTop();

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  return (
    <>
      <ProgressBar />

      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: '100vh',
        }}
      >
        {renderContent}
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------

const ROOTS = {
  MAIN: '/',
  AUTH: '/auth',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: ROOTS.AUTH,
  // DASHBOARD
  dashboard: {
    root: ROOTS.MAIN,
    properties: '/properties',
    users: '/users',
    settings: '/settings',
  },
};

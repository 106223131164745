import { forwardRef } from 'react';
import Link from '@mui/material/Link';
import { BoxProps } from '@mui/material/Box';
import LogoIcon from 'src/assets/icons/logo-icon';
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();
    const isNavMini = settings.themeLayout === 'mini';

    const logo = (
      <LogoIcon
        sx={{
          width: isNavMini ? 64 : 90,
          height: isNavMini ? 'auto' : 40,
          cursor: 'pointer',
          ...sx,
        }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;

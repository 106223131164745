import { useEffect } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { accessToken } from './token';

type Props = {
  children: React.ReactNode;
};

export { accessToken };

// Set access token silently on mount and update it when it changes
function SetAccessTokenSilently({ children }: Props) {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    accessToken.setAccessTokenSilently(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return <>{children}</>;
}

export function AuthProvider({ children }: Props) {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_ADMIN_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_ADMIN_AUTH0_CLIENT_ID!}
      authorizationParams={{
        audience: `https://${process.env.REACT_APP_ADMIN_AUTH0_DOMAIN}/api/v2/`,
        scope: 'openid profile email',
        redirect_uri: window.location.origin,
      }}
    >
      <SetAccessTokenSilently>{children}</SetAccessTokenSilently>
    </Auth0Provider>
  );
}

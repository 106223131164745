import { graphql } from 'src/gql';
import { MutationUpdateSettingsArgs } from 'src/gql/graphql';
import { useMutation, useQuery } from '@tanstack/react-query';
import { graphqlRequest } from './_request';

const getSettingsDocument = graphql(/* GraphQL */ `
  query GetSettings {
    getSettings {
      key
      value
    }
  }
`);

const updateSettingsDocument = graphql(/* GraphQL */ `
  mutation Mutation($data: UpdateSettingsInput!) {
    updateSettings(data: $data)
  }
`);

export function useGetSettingsQuery() {
  return useQuery({
    queryKey: ['settings'],
    staleTime: Infinity,
    queryFn: async () =>
      graphqlRequest({
        document: getSettingsDocument,
      }),
  });
}

export function useUpdateSettingsMutation() {
  return useMutation({
    mutationFn: async (variables: MutationUpdateSettingsArgs) =>
      graphqlRequest({
        document: updateSettingsDocument,
        variables,
      }),
  });
}
